<template>
    <header class="text-center bg-gray-100 p-4 mb-4 text-3xl ">
        <div @click="onClick()" class="cursor-pointer font-extrabold tracking-tighter">wasnjetz</div>
        <p class="text-xs font-normal mt-1 text-gray-600 tracking-wider uppercase">clique enterprise voting software</p>
    </header>
</template>

<script>
export default {
    name: "Header",
    methods: {
        onClick() {
            this.$router.push(`/`)
        }
    }
}
</script>