<template>
    <div class="text-center py-4 border-2 rounded-xl bg-gray-50 text-gray-800 my-8 max-w-sm mx-auto">
      <UserCircleIcon class="h-7 w-7 text-gray-600 mx-auto my-1"/>
      <!-- <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd" />
      </svg> -->

      <div class="pb-2 my-2">

        <p class="font-medium text-md">Moin {{ user.name }}</p>
        
        <div v-if="user.authToken">
          <p class="text-sm uppercase font-bold">Admin Baba nacko</p>
        </div>
        <div v-else>
          <p class="font-small text-xs">Du Nacken</p>
        </div>
      </div>
        
      <button @click="logout()" class="p-1 px-2 transition-colors duration-400 transform bg-gray-400 hover:bg-gray-600 text-gray-100 text-sm rounded-md focus:border-4 border-indigo-300">
        Log Out
      </button>
    </div>
</template>

<script>
import { UserCircleIcon } from '@heroicons/vue/solid'
import { setJSONCookie } from '../cookies'
export default {
  components: { UserCircleIcon },
  name: 'UserHeader',
  props: {
    user: JSON
  },
  methods: {
      logout() {
          setJSONCookie("user", null, 1)
          this.$router.go()
      }
  }, async created() {
    console.log("oi")
  }
}
</script>
