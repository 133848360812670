import {
    createRouter,
    createWebHistory
} from 'vue-router';

import Home from '../views/Home.vue';
import VotableDetail from '../views/VotableDetail.vue';
import VotableEdit from '../views/VotableEdit.vue';
import VotableNew from '../views/VotableNew.vue';
import Login from '../views/Login.vue';
  
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/votable/new',
        name: 'VotableNew',
        component: VotableNew
    },
    {
        path: '/votable/:id',
        name: 'VotableDetail',
        component: VotableDetail
    },
    {
        path: '/edit/:id',
        name: 'VotableEdit',
        component: VotableEdit
    },
    {
        path: '/login/:token',
        name: 'Login',
        component: Login
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
