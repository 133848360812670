<template>
  <main>
    <div class="py-2">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center">
          <p
            class="
              mt-2
              text-3xl
              leading-8
              font-extrabold
              tracking-tight
              text-gray-900
              sm:text-4xl
            "
          >
            {{ votable.title }}
          </p>
          <p
            v-if="votable.description"
            class="mt-2 max-w-2xl text-xl text-gray-500 lg:mx-auto text-center"
          >
            {{ votable.description }}
          </p>

          <div class="mt-3 font-semibold tracking-wide uppercase text-sm">
            <p v-if="votable.votingOpen === true" class="text-indigo-600">
              Voting is open
            </p>
            <p v-if="votable.votingOpen === false" class="text-green-700">Voting is over</p>
          </div>

          <div v-if="votable.outcome" class="my-8">
            <p class="font-semibold text-2xl">Result</p>

            <div class="my-2 flex flex-col justify-center">
              <div v-for="(o, index) in this.sortedOutcome" :key="o.label" class="">

                <div :class="index === 0 ? 'bg-green-500 border-green-500' : 'bg-gray-500 border-gray-500'" 
                  class="bg-opacity-20 border-2  border-opacity-30 rounded-3xl p-3 m-2 inline-flex items-center space-x-4">
                  <div 
                    :class="index === 0 ? 'bg-green-600' : 'bg-gray-600'"
                    class="bg-opacity-20 rounded-2xl p-2">
                    
                    <p 
                    :class="index === 0 ? 'text-2xl' : 'text-xl'"
                    class="font-bold">{{o.votes}}</p>
                    <span 
                    :class="index === 0 ? 'text-sm' : 'text-xs'"
                    class="uppercase font-bold opacity-50" v-text="o.votes === 1 ? 'vote' : 'votes'"></span>
                  </div>

                  <p 
                  :class="index === 0 ? 'text-xl font-semibold' : 'text-lg font-medium'"
                  class="min-w-md">{{o.label}}</p>  
                </div>
                
                <!-- <div v-else class="bg-gray-300 bg-opacity-30 rounded-xl inline-block p-4 m-2">
                  <p class="text-md">{{o.label}}: {{o.votes}}</p>  
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div v-if="votable.votingOpen">
          <div class="mx-auto mt-8 max-w-xxs">
            <div class="flex flex-col">
              <div v-for="option in votable.options" :key="option.id">
                <div class="rounded-lg px-4 py-2 my-1">
                    <label class="flex items-center my-1">
                      <input class="form-radio" type="radio" v-bind:value="option.id" v-model="selectedOptionID" />
                      <span class="ml-2 text-xl font-medium flex-grow">{{
                        option.label
                      }}</span>
                    </label>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-auto mt-8 max-w-xxs text-center">
            <div v-if="userVoted && !userChangedVote" 
              class="flex text-green-800 justify-center items-center bg-green-100 rounded-lg py-2">
              <CheckIcon class="h-5 w-5"/>
              <p class="mx-2">You voted</p>
            </div>
            <div v-else>
              <div v-if="selectedOptionID">
                <Button
                @btn-click="voteFor(selectedOptionID)"
                :text="userChangedVote ? 'Change Vote' : 'Vote'"
                />
              </div>
              <div v-else>
                <p class="text-sm text-gray-700">
                  Select an option to vote
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="errorMessage">
      <p class="text-center text-red-700 font-bold uppercase text-md ">{{errorMessage}}</p>
      <p class="text-center text-red-700 opacity-80 font-semibold italic text-sm mt-1">sheesh diggi</p>
    </div>
  </main>
</template>

<script>
import Button from './Button'
import { CheckIcon } from '@heroicons/vue/outline'
import { getJSONCookie } from '../cookies'

export default {
  name: "VotableDetail",
  props: {},
  components: {
    Button, CheckIcon
  },
  data() {
    return {
      user: JSON,
      votable: JSON,
      selectedOptionID: null,
      errorMessage: null
    };
  },
  computed: {
    userVoted() {
      return !(this.votable.userVoteId == null || this.votable.userVoteId == undefined)
    },
    userChangedVote() {
      return this.userVoted && (this.selectedOptionID != this.votable.userVoteId)
    },
    sortedOutcome: function() {
      function compare(a, b) {
        if (a.votes > b.votes) {
          return -1;
        }
        if (a.votes < b.votes) {
          return 1;
        }
        return 0;
      }
      return this.votable.outcome.slice().sort(compare)
    }
  },
  methods: {
    async fetchDetails() {
      this.errorMessage = null;
      try {
          const res = await fetch(
          `/api/votable/${this.$route.params.id}`, {
            headers: {
              "votingtoken": this.user.votingToken
            }
          }
        );
        const data = await res.json();
        return data;  
      } catch (error) {
        this.errorMessage = "Could not load Voting"
        return {};
      }
    },

    async voteFor(selectedID) {
      this.errorMessage = null;
        try {
          const res = await fetch(`/api/votable/${this.$route.params.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept':'application/json',
            "votingtoken": this.user.votingToken
          },
          body: JSON.stringify({
            selectedOptionId: selectedID
          })
        })
        this.votable = await res.json();  
      } catch (error) {
        this.errorMessage = "Vote failed"
      }
    },
  },
  async created() {
    this.user = getJSONCookie("user")
    this.votable = await this.fetchDetails();
    this.selectedOptionID = this.votable.userVoteId
  },
};
</script>
