<template>
  <main>
    <div class="py-2 mt-10 sm:mt-0">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center">
          <p class=" mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl " >
            {{ votable.title }}
          </p>
          <p v-if="votable.description" class="mt-2 max-w-2xl text-xl text-gray-500 lg:mx-auto text-center" >
            {{ votable.description }}
          </p>

          <div class="my-3 font-semibold tracking-wide uppercase text-sm">
            <p v-if="votable.votingOpen" class="text-indigo-600">
              Voting is open
            </p>
            <p v-else class="text-green-700">Voting is over</p>
          </div>

          <p class="text-gray-800 py-2 font-semibold">
              {{votable.numOfVotes}} people have voted
          </p>

        <div class="my-4 sm:space-x-2 space-x-0 flex sm:flex-row sm:justify-center  flex-col sm:space-y-0 space-y-2">
          <div v-if="votable.votingOpen">
            <Button @btn-click="close()" :text="'Close Voting'" />
          </div>
          <div v-else>
              <Button @btn-click="reopen()" :text="'Reopen Voting'" />
          </div>
          <div>
            <Button @btn-click="deleteVoting()" :text="'Delete Voting'" />
          </div>
        </div>

        <div v-if="errorMessage">
          <p class="text-center text-red-700 font-bold uppercase text-md ">{{errorMessage}}</p>
          <p class="text-center text-red-700 opacity-80 font-semibold italic text-sm mt-1">dirim la</p>
        </div>
      </div>
    </div>
    </div>
  </main>
</template>

<script>
import Button from './Button'
import { getJSONCookie } from "../cookies";

export default {
  name: "VotableEdit",
  components: {
    Button
  },
  data() {
    return {
      user: JSON,
      votable: JSON,
      errorMessage: null
    };
  },
  methods: {
    async fetchDetails() {
      try {
        this.errorMessage = null
        const res = await fetch(
          `/api/votable/${this.$route.params.id}`,
          {
            headers: {
              "authtoken": this.user.authToken
            }
          }
        );
        const data = await res;
        const json = data.json()
        return json;
      } catch (error) {
        this.errorMessage = "failed to fetch"
        return {};
      }
    },
    async close() {
      try {
        this.errorMessage = null
        await fetch(`/api/votable/close/${this.$route.params.id}`, {
          method: 'POST',
          headers: {
          'authtoken': this.user.authToken
          }
        })  
        this.$router.go();
      } catch (error) {
        console.log(error) 
        this.errorMessage = "failed to close"
      }
    },
    async reopen() {
      try {
        this.errorMessage = null
        await fetch(`/api/votable/reopen/${this.$route.params.id}`, {
          method: 'POST',
          headers: {
          'authtoken': this.user.authToken
          }
        })  
        this.$router.go();
      } catch (error) {
        console.log(error) 
        this.errorMessage = "failed to reopen"
      }
    },
    async deleteVoting() {
      if (confirm('Are you sure you want to delete? Can not be undone')) {
        try {
          this.errorMessage = null
          await fetch(`/api/votable/${this.$route.params.id}`, {
            method: 'DELETE',
            headers: {
            'authtoken': this.user.authToken
            }
          })  
          console.log("todo bien") 
          this.$router.push(`/`);
        } catch (error) {
          console.log(error) 
          this.errorMessage = "failed to delete"
        }  
      } else {
        // Do nothing!
        console.log('Not deleted');
      }
    }
  },
  async created() {
    this.user = getJSONCookie("user");
    this.votable = await this.fetchDetails();
  },
};
</script>

<style>
</style>