<template>
    <button @click="onClick()" class="p-2 px-5 transition-colors duration-400 transform bg-indigo-500 hover:bg-indigo-400 text-gray-100 text-lg rounded-lg focus:border-4 border-indigo-300">
    {{ text }}
    </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String
  },
  methods: {
    onClick() {
      this.$emit('btn-click')
    },
  },
}
</script>
