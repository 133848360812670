<template>
    <main>
        <div v-if="errorMessage">
          <p class="text-center text-red-700 font-bold uppercase text-md ">{{errorMessage}}</p>
          <p class="text-center text-red-700 opacity-80 font-semibold italic text-sm mt-1">dulli</p>
        </div>
        <div v-else>
          <h1 class="text-center text-md font-semibold p-2 ">Logging in...</h1>
        </div>
    </main>
</template>

<script>
import { setJSONCookie } from '../cookies'

export default {
  name: 'Login',
  props: {
  },
  components: {

  },
  data() {
      return {
        errorMessage: null
      }
  },
  methods: {
    async fetchUser() {
      this.errorMessage = null;
      try {
        const res = await fetch(
        `/api/login/${this.$route.params.token}`
        );
        const data = await res.json();
        return data;  
      } catch (error) {
        console.log(error)
        this.errorMessage = "Login failed"
        return null
      }
    },
  },
  async created() {
      const user = await this.fetchUser()
      if (user !== null) {
        setJSONCookie("user", user, 7)
        this.$router.push(`/`)
      }
  }
}
</script>
