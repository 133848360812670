<template>
    <div v-if="user">
        <div v-if="errorMessage" class="py-2">
            <p class="text-center text-red-700 font-bold uppercase text-md ">{{errorMessage}}</p>
            <p class="text-center text-red-700 opacity-80 font-semibold italic text-sm mt-1">sheesh diggi</p>
        </div>
        <div v-else>
            <VotableList :votables="votables" :canEdit="user.authToken != null"/>
        </div>
        <UserHeader :user="user" />
        
    </div>
    
    <div v-else>
        <div class="text-center flex flex-col content-center py-3">
            <svg xmlns="http://www.w3.org/2000/svg" 
                class="mx-auto h-7 w-7 text-pink-800 my-1" 
                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div class="mt-2">
                <p class="text-md font-semibold">You are not logged in</p>
                <p class="text-sm">Use your magic link to log in</p>
            </div>
            <button @click="yoink()" class="mx-auto mt-5 p-2 px-4 transition-colors duration-400 transform bg-gray-500 hover:bg-gray-400 text-gray-100 text-xs rounded-lg focus:border-4 border-gray-300">
                Admin Access
            </button>
        </div>        
    </div>
</template>

<script>
import UserHeader from './UserHeader'
import VotableList from './VotableList'
import { getJSONCookie } from '../cookies'

export default {
  name: 'Home',
  props: {
  },
  components: {
      UserHeader,
      VotableList,
  },
  data() {
      return {
          user: [],
          votables: [],
          errorMessage: null
      }
  },
  methods: {
      async fetchVotables() {
          this.errorMessage = null
          try {
            const res = await fetch('/api/votables', {
              headers: {
                  "votingtoken": this.user.votingToken
              }
            })
            const data = await res.json()
            return data    
          } catch (error) {
            this.errorMessage = "Could not load any votings"
            return {}
          }
      },
      async yoink() {
          window.location.href = `https://www.youtube.com/watch?v=QTT5iHCHSn0`
      }
  },
  async created() {
      this.user = getJSONCookie("user")
      if (this.user !== null) {
          this.votables = await this.fetchVotables()
      }   
  }
}
</script>
