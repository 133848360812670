<template>
  <div class="mt-10 sm:mt-0">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">New Voting</h3>
          <p class="mt-1 text-sm text-gray-600">
            Create a new voting
          </p>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form v-on:submit="submit">
          <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">Titel</label>
                  <input type="text" v-model="wip.name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <label class="block text-sm font-medium text-gray-700">Description</label>
                  <input type="text" v-model="wip.description" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                    <label for="about" class="block text-sm font-medium text-gray-700">
                        Options
                    </label>
                    <div class="mt-1">
                        <textarea id="about" v-model="wip.options" name="about" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"></textarea>
                    </div>
                    <p class="mt-2 text-sm text-gray-500">
                        One option per line
                    </p>
                </div>

              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getJSONCookie } from "../cookies";

export default {
    name: "VotableNew",
    props: {
    },
    data() {
        return {
            user: JSON,
            wip: JSON
        };
    },
    methods: {
        submit: async function (e) {
            e.preventDefault()
            console.log("subbo")
            console.log(this.wip)

            // // console.log(selectedID)
            const res = await fetch(`/api/votable/new`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Accept':'application/json',
                'authtoken': this.user.authToken
                },
                body: JSON.stringify({
                    name: this.wip.name,
                    description: this.wip.description,
                    options: this.wip.options.split("\n").map(o => o.trim())
                })
            })

            const votable = await res.json();
            console.log(votable)
            this.$router.push(`/votable/${votable.id}`);
        },
    },
    async created() {
      this.user = getJSONCookie("user");
    }
}
</script>

<style>

</style>