<template>
  <div class="flex flex-col sm:px-6 lg:px-8 mx-2">
    <p class="my-2 mx-2 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl" >
      Votings
    </p>
    <div class="overflow-x-auto max-x-sm">
      <div class="py-2 align-middle inline-block min-w-full ">
        <div class="shadow overflow-hidden border-b border-gray-200 rounded-lg mx-2" >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                >
                  Name
                </th>
                <th scope="col" class=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider " >
                  Status
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                class="cursor-pointer"
                v-for="votable in votables"
                :key="votable.id"
              >
                <td class="px-6 py-4 whitespace-nowrap" v-on:click="votableClick(votable)">
                  <div class="flex items-center">
                    <div class="ml-4 text-left">
                      <div class="text-sm font-medium text-gray-900">
                        {{ votable.title }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ votable.description }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap" v-on:click="votableClick(votable)">
                  <span class=" px-2 mx-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                    {{votable.numOfVotes}} {{ votable.numOfVotes == 1 ? 'Vote' : 'Votes'}}
                  </span>
                  <span v-if="votable.votingOpen && votable.userVoteId == null"
                    class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                    Vote now
                  </span>
                  <span v-else-if="votable.votingOpen"
                    class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Voted
                  </span>
                  <span v-else class=" px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                    Voting Over
                  </span>
                </td>
                <td v-if="canEdit" v-on:click="editClick(votable)" 
                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-gray-50 text-center font-bold">
                  Edit
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="canEdit">
              <p 
                v-on:click="addVotingClick()"
                class="text-center cursor-pointer py-3 text-sm text-blue-900 font-semibold bg-blue-50 hover:bg-blue-100"
              >Add Voting</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "VotableList",
  props: {
    votables: Array,
    canEdit: Boolean
  },
  methods: {
    votableClick: function (votable) {
      this.$router.push(`/votable/${votable.id}`);
    },
    editClick: function (votable) {
      this.$router.push(`/edit/${votable.id}`);
    },
    addVotingClick: function () {
      this.$router.push(`/votable/new`);
    }
  },
};
</script>
